import React from 'react';
import { EmailSharp, LocationOnSharp, Phone } from '@mui/icons-material';

export const CONTACT_INFO = [
  {
    icon: <LocationOnSharp fontSize="large" sx={{ color: '#F26E21' }} />,
    title: 'Location',
    content: '9205 Coleman Road STE 100, Roswell, GA 30075',
  },
  {
    icon: <Phone fontSize="large" sx={{ color: '#F26E21' }} />,
    title: 'Make A Call',
    content: '(912) 470-6152',
  },
  {
    icon: <EmailSharp fontSize="large" sx={{ color: '#F26E21' }} />,
    title: 'Send A Mail',
    content: 'info@nucleonspace.com',
  },
];

export const TEAM_MEMBERS = [
  {
    id: 'team-member-1',
    name: 'Zac Diggins',
    position: 'CEO and Co-Founder',
    email: 'mailto:info@nucleonspace.com',
    image: 'Zacpic.jpg',
    linkedin: 'https://www.linkedin.com/in/zacharydiggins/',
  },
  {
    id: 'team-member-2',
    name: 'Omair Ahmad',
    position: 'Co-Founder',
    email: 'mailto:info@nucleonspace.com',
    image: 'OmairPic.jpg',
    linkedin: 'https://www.linkedin.com/in/omair-ahmad-766b2b82/',
  },
  {
    id: 'team-member-3',
    name: 'Dante Castelli',
    position: 'Co-Founder',
    email: 'mailto:info@nucleonspace.com',
    image: 'DantePic.jpg',
    linkedin: 'https://www.linkedin.com/in/dante-castelli-835ab862/',
  },
  {
    id: 'team-member-4',
    name: 'Henry Diggins',
    position: 'Lead Product Development Engineer',
    email: 'mailto:info@nucleonspace.com',
    image: 'HenryPic.jpg',
    linkedin: 'https://www.linkedin.com/in/henry-diggins-7a23ab163/',
  },
  {
    id: 'team-member-5',
    name: 'Cedric Kong',
    position: 'Hardware Design Engineer',
    email: 'mailto:info@nucleonspace.com',
    image: 'CedricPic.jpg',
    linkedin: 'https://www.linkedin.com/in/cedrickong/',
  },
  {
    id: 'team-member-6',
    name: 'Kirsten Diggins',
    position: 'Data Scientist',
    email: 'mailto:info@nucleonspace.com',
    image: 'KirstenPic.jpg',
    linkedin: 'https://www.linkedin.com/in/kirsten-diggins-9752b4b7/',
  },
  {
    id: 'team-member-7',
    name: 'Alejandro Montes',
    position: 'Laboratory Technician',
    email: 'mailto:info@nucleonspace.com',
    image: 'AlejandroPic.jpg',
    linkedin: 'https://www.linkedin.com/in/alejandro-montes-9a3523317/',
  },
  {
    id: 'team-member-8',
    name: 'Kaden Matsen',
    position: 'Hardware Design Engineer',
    email: 'mailto:info@nucleonspace.com',
    image: 'KadenPic.jpg',
    linkedin: 'https://www.linkedin.com/in/kaden-matsen/',
  },
];