import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Header.css'; // Make sure the path to your CSS file is correct

const Header = () => {
  // Function to handle login redirection
  const handleLogin = () => {
    // const domain = "nucleon";
    const responseType = "code";
    const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
    const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}`;
    const loginUrl = `https://auth.nucleonspace.com/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=email%20openid%20profile&response_mode=fragment&prompt=login`;

    //console.log(loginUrl);
    window.location.assign(loginUrl);
  };

  return (
    <Navbar expand="lg" className="orange-navbar" id="header-bar">
      <Container>
        <Navbar.Brand href="/" id="NucleonBrand">NUCLEON</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="products">PRODUCTS</Nav.Link>
            <Nav.Link href="test-solutions">TEST SOLUTIONS</Nav.Link>
            <Nav.Link href="pricing">PRICING</Nav.Link>
            {/* <Nav.Link href="about">ABOUT</Nav.Link> */}
            <Nav.Link href="contact">CONTACT</Nav.Link>
          </Nav>
          <Nav>
            {/* Updated LOGIN Nav.Link to use onClick event for handleLogin */}
            <Nav.Link href="#login" onClick={handleLogin}>LOGIN</Nav.Link>
            <Button variant="primary" onClick={handleLogin}>REGISTER</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
